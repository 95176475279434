import React from "react"
import styled from "styled-components"

const Box = styled.main`
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-top: 1.5rem;
  background: #fff;

  @media screen and (min-width: 1500px) {
    width: 87%;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    margin-top: 0;
  }
`

const Ele = ({ children }) => {
  return <Box>{children}</Box>
}
export default Ele
