import React, { useEffect } from "react"
import { Provider } from "../../utilities/context"
import Reset from "../../styles/reset"
import Theme from "../../styles/theme"
import Styles from "../../styles/shared"
import PageWrap from "./pageWrap"
import Menu from "../menu/wrap"
import Footer from "../footer/wrap"
import Loader from "../layout/loader"
import Chat from "../layout/chat"

const Shell = props => {
  const slug = props.path
  const isEducationPage = slug.includes("education")

  useEffect(() => {
    if (isEducationPage) {
      let html = document.documentElement
      html.classList.add("is-education")
    }
  })
  return (
    <Provider>
      <Theme>
        <Reset />
        <Styles />
        <Loader />

        {/* general pages */}
        {!isEducationPage && (
          <PageWrap>
            <Chat />
            <Menu />
            {props.children}
            <Footer />
          </PageWrap>
        )}

        {/* education ppc */}
        {isEducationPage && <>{props.children}</>}
      </Theme>
    </Provider>
  )
}

export default Shell
