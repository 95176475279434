import React from "react"
import styled from "styled-components"
import Icon from "../../images/whatsapp.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Link = styled.a`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: ${props => props.theme.mint};
  width: 3.35rem;
  height: 3.35rem;
  z-index: 100000;
  background-image: url("${Icon}");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 100%;

  @media screen and (max-width: 766px) {
    right: 1rem;
    bottom: 1rem;
  }
`
const Chat = () => {
  const logEvent = () => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Chat Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Learn",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 1,
    })
  }

  return (
    <Link
      href="https://wa.me/256392177488"
      onClick={() => {
        logEvent()
      }}
      title="Chat on WhatsApp"
    ></Link>
  )
}

export default Chat
