import React, { createContext, useReducer, useContext } from "react"

const defaultState = {
  theme: "dark",
  firstDeposit: 0,
  monthlyDeposit: 0,
  investmentYears: 0,
}

const GlobalStateContext = createContext(defaultState)
const GlobalDispatchContext = createContext()

const globalReducer = (state, action) => {
  switch (action.type) {
    case "SET_THEME": {
      return {
        ...state,
        theme: action.theme,
      }
    }

    case "SET_FIRST_DEPOSIT": {
      return {
        ...state,
        firstDeposit: action.value,
      }
    }

    case "SET_MONTHLY_DEPOSIT": {
      return {
        ...state,
        monthlyDeposit: action.value,
      }
    }

    case "SET_INVESTMENT_YEARS": {
      return {
        ...state,
        investmentYears: action.value,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const Provider = ({ children }) => {
  let [state, dispatch] = useReducer(globalReducer, defaultState)

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
export const useGlobalStateContext = () => useContext(GlobalStateContext)
