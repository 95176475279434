import { createGlobalStyle } from "styled-components"

const SharedStyles = createGlobalStyle`
    html{
        font-size: 16px;
        scroll-behavior: smooth;

        @media screen and (min-width: 1920px) {
            font-size: 0.9vw;
        }

        @media screen and (max-width: 1400px) {
            font-size: 13px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 12px;
        }

        @media (max-width: 766px) {
            font-size: 13px;
        }

        &.is-education {
            font-size: 16px;
            
            body {
                background: ${props => props.theme.darkblue}; 
                color: #fff; 
            }

            @media (min-width: 1919px) {
                font-size: 24px;
            }

            @media screen and (min-width: 1500px) {
                body {
                    background-color:#0a0e15;
                }
            }

            @media (max-width: 1023px) {
                font-size: 12px;
            }

            @media (max-width: 766px) {
                font-size: 15px;
            }
        }
    }
     body {
        -webkit-font-smoothing: antialiased;
        background-color: #d3d6e0; 
        color: ${props => props.theme.black};
        font-family: var(--xn-font-body);
        font-weight: 400;
    }

    h1,h2{
        font-family: var(--xn-font-title);
        font-weight: 700;
    }
`
export default SharedStyles
