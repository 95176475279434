import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import Icon from "../shared/icon"

const slideIn = keyframes`
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform:none; 
  }
`

const Items = [
  ["Personal", "https://join.myxeno.com"],
  ["Groups", "https://join.myxeno.com"],
  ["Institutions", "https://join.myxeno.com"],
  ["Company", "https://myxeno.com/company"],
  ["Help", "https://support.myxeno.com"],
  ["Login", "https://myxeno.com/login"],
  ["Sign up", "https://join.myxeno.com"],
]

const Box = styled.ul`
  padding-right: 0rem;
  height: 100%;

  li {
    height: 100%;
    display: inline-block;
  }

  a {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.1px;
    font-weight: 700;
    padding: 0 2rem;
    display: flex;
    height: 100%;
    align-items: center;

    &.is-active,
    &:hover {
      color: ${props => props.theme.mint};
    }
  }

  @media screen and (max-width: 766px) {
    position: absolute;
    z-index: 5;
    width: 100%;
    left: 0;
    top: 4rem;
    background: ${props => props.theme.black};
    height: auto;
    padding-right: 0;
    padding: 0 4rem;

    li {
      height: auto;
      border-bottom: 1px solid #0e1622;
      display: block;
      height: 4rem;
    }

    a {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
    }

    &.false {
      display: none;
    }

    &.true {
      display: block;
      animation: ${slideIn} 0.5s ease both;
    }
  }
`

const Toggle = styled.button`
  height: 4rem;
  width: 4rem;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #26344a;

  div {
    width: 1.125rem;
    height: 1.125rem;
    display: none;
  }

  .menu.false {
    display: block;
  }

  .close.true {
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: ${props => props.theme.mint};
  }

  @media screen and (max-width: 766px) {
    display: flex;
  }
`

const MenuItems = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let anchors = document.querySelectorAll("a")
    anchors.forEach(ele => {
      ele.addEventListener("click", () => {
        setOpen(false)
      })
    })
  }, [])

  return (
    <>
      <Toggle
        className={"" + open}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className={"menu " + open}>
          <Icon title="menu" />
        </div>

        <div className={"close " + open}>
          <Icon title="close" />
        </div>
      </Toggle>

      <Box className={"menu-items-wrap " + open}>
        {Items &&
          Items.map((ele, index) => {
            return (
              <li key={index}>
                {ele[1].substring(0, 4) !== "http" && (
                  <Link title={ele[0]} activeClassName="is-active" to={ele[1]}>
                    {ele[0]}
                  </Link>
                )}

                {ele[1].substring(0, 4) === "http" && (
                  <a title={ele[0]} href={ele[1]} rel="noreferrer">
                    {ele[0]}
                  </a>
                )}
              </li>
            )
          })}
      </Box>
    </>
  )
}

export default MenuItems
