import React from "react"
import styled from "styled-components"
import Logo from "./logo"
import Items from "./items"

const Box = styled.nav`
  height: 5rem;
  background: ${props => props.theme.black};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 766px) {
    height: 4rem;
  }
`

const Wrap = () => {
  return (
    <Box>
      <Logo />
      <Items />
    </Box>
  )
}

export default Wrap
