import React from "react"
import styled from "styled-components"
import Overline from "../shared/overline"
import CMA from "../../images/logos/cma.jpg"
import KCB from "../../images/logos/kcb_small.jpg"
import SB from "../../images/logos/sb_small.jpg"
import EY from "../../images/logos/ey_small.jpg"
import MOMO from "../../images/logos/mtn_momo_small.jpg"
import URBRA from "../../images/logos/urbra_logo_small.jpg"

const Partners = [
  {
    type: "Regulator",
    class: "cma",
    title: "Capital Markets Authority",
    url: "https://cmauganda.co.ug/",
    logo: CMA,
  },
  {
    type: "Regulator",
    class: "urbra",
    title: "Uganda Retirement Benefits Regulatory Authority",
    url: "https://urbra.go.ug/",
    logo: URBRA,
  },
  {
    type: "Trustee",
    class: "kcb",
    title: "KCB Uganda",
    url: "https://ug.kcbgroup.com/",
    logo: KCB,
  },
  {
    type: "Custodian",
    class: "sbu",
    title: "Stanbic Bank Uganda",
    url: "https://www.stanbicbank.co.ug/",
    logo: SB,
  },
  {
    type: "Auditor",
    class: "eyu",
    title: "Ernst and Young",
    url: "https://www.ey.com/en_ug",
    logo: EY,
  },
  {
    type: "Mobile Payments",
    class: "momo",
    title: "MTN Uganda",
    url: "https://www.mtn.co.ug/",
    logo: MOMO,
  },
]

const Box = styled.div`
  padding-top: 4rem;
  background-color: #fff;
  padding-bottom: 5.5rem;

  > p {
    width: 40%;
    margin: 0 auto;
    padding-bottom: 3.5rem;
    text-align: center;
    /* font-size: 0.875rem; */
    line-height: 1.6;
    color: ${props => props.theme.darkblue};
  }

  .is-dark & {
    background-color: ${props => props.theme.black};
  }

  @media screen and (max-width: 766px) {
    > p {
      width: 100%;
      padding: 2rem;
    }
  }
`

const Logos = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  a {
    display: flex;
    width: 5.25rem;
    height: 5.25rem;
    margin: 0 2.5rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;

      &.urbra {
        height: 73%;
      }

      &.kcb {
        height: 50%;
      }

      &.momo {
        height: 75%;
      }
    }
  }

  span {
    text-align: center;
    color: ${props => props.theme.darkblue};
    letter-spacing: 0;
    font-size: 0.625rem;
    opacity: 0.8;
  }

  @media screen and (max-width: 766px) {
    flex-wrap: wrap;

    a {
      margin: 0 1.5rem;
      margin-bottom: 1rem;
    }
  }
`

const Splash = () => {
  return (
    <Box>
      <p>
        XENO and our partners execute your investments using a diversified
        portfolio of unit trust funds ensuring the highest standards of ethical
        and professional conduct.
      </p>
      <Logos>
        {Partners.map((item, index) => {
          return (
            <div key={index}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <img
                  className={item.class}
                  src={item.logo}
                  alt={item.title + " logo"}
                />
              </a>
              <Overline>{item.type}</Overline>
            </div>
          )
        })}
      </Logos>
    </Box>
  )
}
export default Splash
