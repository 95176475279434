import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Group = styled.li`
  width: 25%;

  span {
    color: ${props => props.theme.mint};
    display: block;
    padding-bottom: 2.25rem;
    font-weight: 600;
    letter-spacing: -0.1px;
  }

  a {
    display: block;
    padding: 0.5rem 0;

    &:hover {
      color: ${props => props.theme.mint};
    }
  }

  p {
    line-height: 1.625rem;
  }

  @media screen and (max-width: 766px) {
    width: 50%;
    padding-bottom: 4.5rem;
  }
`

const Box = styled.ul`
  display: flex;

  @media screen and (max-width: 766px) {
    flex-wrap: wrap;
  }
`

const Links = [
  [
    "Legal",
    [
      ["Terms of Use", "https://myxeno.com/terms-of-use"],
      ["Privacy Policy", "https://myxeno.com/privacy-policy"],
      [
        "Client Agreement",
        "https://drive.google.com/file/d/1ooml3E4Fx-UqQYJzHctzhpQwKML25gI8/view?usp=sharing",
      ],
    ],
  ],
  [
    "Learn",
    [
      ["About", "https://myxeno.com/company"],
      ["Learn", "/"],
      ["Support", "/support"],
    ],
  ],
  [
    "My Account",
    [
      ["Create Account", "https://myxeno.com/select-goal"],
      ["Login", "https://myxeno.com/login"],
      ["Forgot Password", "https://myxeno.com/recover-account"],
    ],
  ],
]

const Sitemap = () => {
  return (
    <Box>
      {Links &&
        Links.map((item, index) => (
          <Group key={index}>
            <span>{item[0]}</span>
            {item[1] &&
              item[1].map((link, id) => (
                <div key={id}>
                  {link[1].substring(0, 4) === "http" && (
                    <a href={link[1]}>{link[0]}</a>
                  )}

                  {link[1].substring(0, 4) !== "http" && (
                    <Link to={link[1]}>{link[0]}</Link>
                  )}
                </div>
              ))}
          </Group>
        ))}

      <Group>
        <span>Contact</span>
        <p>
          1st Floor, Workers House, 1 Pilkington Road, Kampala, Uganda Call or
          Whatsapp: +256 392 177 488
        </p>
      </Group>
    </Box>
  )
}

export default Sitemap
