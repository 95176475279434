import React from "react"
import styled from "styled-components"
import XenoLogo from "../../images/xeno-logo-square_fill-mint.svg"

const Box = styled.figure`
  height: 5rem;
  width: 5rem;
  flex-shrink: 0;

  a {
    display: block;
    height: 100%;
    background-image: url("${XenoLogo}");
    background-size: contain;
    background-repeat: no-repeat;
  }
  span {
    opacity: 0;
    visibility: hidden;
  }

  &.med {
    width: 5.5rem;
    height: 5.5rem;
  }

  &.float {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 766px) {
    width: 4rem;
    height: 4rem;

    &.med {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
`

const Logo = ({ className }) => {
  return (
    <Box className={className}>
      <a href="https://myxeno.com">
        <span>XENO</span>
      </a>
    </Box>
  )
}

export default Logo
