import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Icon from "./icon"

const Box = styled.div`
  text-align: center;
  padding-top: 4.5rem;

  a {
    display: inline-block;
    padding: 1.5rem;
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }

    div {
      width: 1.125rem;
      height: 1.125rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      path {
        fill: ${props => props.theme.black};
      }
    }
  }
`

const Socials = ({ slug, title }) => {
  let [Links, setLinks] = useState([
    ["facebook", "https://www.facebook.com/XenoUganda", "#1777f2"],
    ["twitter", "https://twitter.com/XenoUganda", "#389ce4"],
    ["whatsapp", "https://wa.me/256392177488", "#5ace73"],
    ["envelop", "mailto:uganda@myxeno.com", "#173b86"],
  ])

  useEffect(() => {
    if (slug) {
      setLinks([
        [
          "facebook",
          "https://www.facebook.com/sharer/sharer.php?u=https://invest.myxeno.com/articles/" +
            slug,
          "#1777f2",
        ],
        [
          "twitter",
          "https://twitter.com/intent/tweet?url=https://invest.myxeno.com/articles/" +
            slug +
            "&text=" +
            title,
          "#389ce4",
        ],
        [
          "whatsapp",
          "https://wa.me/?text=https://invest.myxeno.com/articles/" + slug,
          "#5ace73",
        ],
        [
          "envelop",
          "mailto:?&subject=" +
            title +
            "&body=https://invest.myxeno.com/articles/" +
            slug,
          "#173b86",
        ],
      ])
    }
  }, [slug, title])

  return (
    <Box className="xn-socials">
      {Links.map((ele, index) => {
        return (
          <a
            key={index}
            href={ele[1]}
            rel="noreferrer"
            title={ele[0]}
            target="_blank"
          >
            <div>
              <Icon title={ele[0]} fill={ele[2]}></Icon>
            </div>
          </a>
        )
      })}
    </Box>
  )
}

export default Socials
