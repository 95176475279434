import React from "react"
import styled, { keyframes } from "styled-components"

const spin = keyframes`
    0%{
        transform: none; 
    }

    100% {
        transform: rotateZ(360deg);
    }
`

const Box = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  position: fixed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .page-has-loaded & {
    display: none;
  }

  div {
    width: 2.5rem;
    height: 2.5rem;
    animation: ${spin} 0.3s linear both infinite;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const Loader = () => {
  return (
    <Box>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path
            d="M7.8 15.6C3.6 15.6.2 12.2.2 8c0-3 1.7-5.7 4.4-6.9.2-.2.4-.1.5.1.1.2 0 .4-.2.5-2.4 1.1-4 3.6-4 6.2 0 3.8 3.1 6.9 6.9 6.9 3.8 0 6.9-3.1 6.9-6.9 0-2.9-1.9-5.6-4.7-6.5-.2 0-.3-.2-.2-.4.1-.2.3-.3.5-.2 3.1 1.1 5.2 4 5.2 7.2-.1 4.2-3.5 7.6-7.7 7.6z"
            fill="#00e2c1"
          />
        </svg>
      </div>
    </Box>
  )
}

export default Loader
