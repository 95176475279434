import React from "react"
import styled from "styled-components"
import Sitemap from "./sitemap"
import Actions from "./actions"
import Legal from "./legal"
import Socials from "../shared/socials"
import Regulators from "./regulators"

const Box = styled.footer`
  background: ${props => props.theme.black};
  color: #fff;
  padding: 7.5rem 9rem 7.5rem;
  margin-bottom: 3rem;

  &.clear-bottom {
    margin-bottom: 0;
  }

  .xn-socials {
    a {
      div {
        height: 1.35rem;
        width: 1.35rem;
      }

      &:hover {
        path {
          fill: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 766px) {
    padding: 3rem;
    margin: 0;
  }
`

const Wrap = ({ className }) => {
  return (
    <>
      {/* <Regulators /> */}
      <Box className={className}>
        <Legal />
      </Box>
    </>
  )
}

export default Wrap
