import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { rem } from "../../utilities/pxtorem"

const Box = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.mint};
  color: ${props => props.theme.black};
  display: inline-block;

  button,
  a {
    display: block;
    padding: 1.125rem 2.25rem;
    text-transform: uppercase;
    font-size: ${rem(13)};
  }

  span {
    font-weight: bold;
  }

  &.outline {
    background-color: transparent;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border: solid 2px ${props => props.theme.mint};
      width: 100%;
      height: 100%;
    }
  }

  &.dark {
    color: #fff;
    background-color: ${props => props.theme.black};

    button {
      color: #fff;
    }
  }

  @media screen and (max-width: 766px) {
    button,
    a {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
`

const Button = ({ label, to, theme, click }) => {
  return (
    <Box
      className={theme}
      onClick={() => {
        click()
      }}
    >
      {!to && (
        <button>
          <span>{label}</span>
        </button>
      )}

      {to && to.substring(0, 4) !== "http" && (
        <Link to={to}>
          <span>{label}</span>
        </Link>
      )}

      {to && to.substring(0, 4) === "http" && (
        <a href={to} rel="noreferrer" title={label} target="_blank">
          <span>{label}</span>
        </a>
      )}
    </Box>
  )
}

export default Button
