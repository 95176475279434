import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Icon from "../shared/icon"
import Pattern from "../shared/pattern"

const Box = styled.div`
  display: flex;
  padding-bottom: 11.25rem;

  h2 {
    font-size: 1.5rem;
    color: ${props => props.theme.mint};
    padding-bottom: 2rem;
  }

  p {
    padding-bottom: 3.5rem;
    width: 80%;
    line-height: 1.75rem;
    height: 9.25rem;
  }

  .xn-pattern {
    position: absolute;
    right: 1rem;
    top: 0;
    height: 1.7rem;
    width: 6.4rem;
    z-index: 0;
  }

  @media screen and (max-width: 766px) {
    display: block;

    p {
      width: 100%;
      height: auto;
    }
  }
`

const IconWrap = styled.figure`
  height: 2.4rem;
  width: 2.4rem;
  margin-bottom: 2.75rem;
  position: relative;
  left: -3rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 766px) {
    left: 0;
  }
`

const Item = styled.div`
  width: 50%;
  position: relative;

  &:last-child {
    left: 5.5rem;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    padding-bottom: 7.5rem;

    &:last-child {
      left: 0;
      padding-bottom: 0;
    }
  }
`

const Actions = () => {
  return (
    <Box>
      <Item>
        <IconWrap>
          <Icon title="chat" fill="#00e2c1" />
        </IconWrap>
        <h2>Speak to an advisor</h2>
        <p>
          Get professional investment guidance from an Advisor on WhatsApp about
          investing with XENO.
        </p>
        <Button
          to="https://web.whatsapp.com/send?phone=256392177488&amp;text&amp;app_absent=0"
          label="Chat on WhatsApp"
        />
        <Pattern size="4" />
      </Item>

      <Item>
        <IconWrap>
          <Icon title="heart" fill="#00e2c1" />
        </IconWrap>
        <h2>From as little as UGX 10,000 or KES 500</h2>
        <p>
          Saving money is an excellent first step toward financial freedom. XENO
          helps you to invest your savings and grow your wealth over time.
        </p>
        <Button
          label="Create Account"
          to="https://join.myxeno.com"
          click={() => {
            return false
          }}
        />
        <Pattern size="4" />
      </Item>
    </Box>
  )
}

export default Actions
