import React from "react"

const Icon = ({ title, fill }) => {
  return (
    <>
      {title === "arrow-ltr" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      )}

      {title === "loader" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path
            d="M7.8 15.6C3.6 15.6.2 12.2.2 8c0-3 1.7-5.7 4.4-6.9.2-.2.4-.1.5.1.1.2 0 .4-.2.5-2.4 1.1-4 3.6-4 6.2 0 3.8 3.1 6.9 6.9 6.9 3.8 0 6.9-3.1 6.9-6.9 0-2.9-1.9-5.6-4.7-6.5-.2 0-.3-.2-.2-.4.1-.2.3-.3.5-.2 3.1 1.1 5.2 4 5.2 7.2-.1 4.2-3.5 7.6-7.7 7.6z"
            fill="#fff"
          />
        </svg>
      )}

      {title === "facebook" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96.124"
          height="96.123"
          viewBox="0 0 96.124 96.123"
        >
          <path
            fill={fill}
            d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 00-1.96 1.961v15.803a1.96 1.96 0 001.96 1.96H36.57v39.876a1.96 1.96 0 001.96 1.96h16.352a1.96 1.96 0 001.96-1.96V54.287h14.654a1.96 1.96 0 001.96-1.96l.006-15.803a1.963 1.963 0 00-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 001.959-1.96V1.98A1.96 1.96 0 0072.089.02z"
          />
        </svg>
      )}

      {title === "plus-white" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      )}

      {title === "award" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="8" r="7"></circle>
          <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
        </svg>
      )}

      {title === "phone" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
          <line x1="12" y1="18" x2="12.01" y2="18"></line>
        </svg>
      )}

      {title === "twitter" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill={fill}
            d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
          />
        </svg>
      )}

      {title === "envelop" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.626 511.626">
          <g fill={fill}>
            <path d="M49.106 178.729c6.472 4.567 25.981 18.131 58.528 40.685 32.548 22.554 57.482 39.92 74.803 52.099 1.903 1.335 5.946 4.237 12.131 8.71 6.186 4.476 11.326 8.093 15.416 10.852 4.093 2.758 9.041 5.852 14.849 9.277 5.806 3.422 11.279 5.996 16.418 7.7 5.14 1.718 9.898 2.569 14.275 2.569h.575c4.377 0 9.137-.852 14.277-2.569 5.137-1.704 10.615-4.281 16.416-7.7 5.804-3.429 10.752-6.52 14.845-9.277 4.093-2.759 9.229-6.376 15.417-10.852 6.184-4.477 10.232-7.375 12.135-8.71 17.508-12.179 62.051-43.11 133.615-92.79 13.894-9.703 25.502-21.411 34.827-35.116 9.332-13.699 13.993-28.07 13.993-43.105 0-12.564-4.523-23.319-13.565-32.264-9.041-8.947-19.749-13.418-32.117-13.418H45.679c-14.655 0-25.933 4.948-33.832 14.844C3.949 79.562 0 91.934 0 106.779c0 11.991 5.236 24.985 15.703 38.974 10.466 13.99 21.604 24.983 33.403 32.976z" />
            <path d="M483.072 209.275c-62.424 42.251-109.824 75.087-142.177 98.501-10.849 7.991-19.65 14.229-26.409 18.699-6.759 4.473-15.748 9.041-26.98 13.702-11.228 4.668-21.692 6.995-31.401 6.995h-.578c-9.707 0-20.177-2.327-31.405-6.995-11.228-4.661-20.223-9.229-26.98-13.702-6.755-4.47-15.559-10.708-26.407-18.699-25.697-18.842-72.995-51.68-141.896-98.501C17.987 202.047 8.375 193.762 0 184.437v226.685c0 12.57 4.471 23.319 13.418 32.265 8.945 8.949 19.701 13.422 32.264 13.422h420.266c12.56 0 23.315-4.473 32.261-13.422 8.949-8.949 13.418-19.694 13.418-32.265V184.437c-8.186 9.132-17.7 17.417-28.555 24.838z" />
          </g>
        </svg>
      )}

      {title === "whatsapp" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill={fill}
            d="M256.064 0h-.128C114.784 0 0 114.816 0 256c0 56 18.048 107.904 48.736 150.048l-31.904 95.104 98.4-31.456C155.712 496.512 204 512 256.064 512 397.216 512 512 397.152 512 256S397.216 0 256.064 0zm148.96 361.504c-6.176 17.44-30.688 31.904-50.24 36.128-13.376 2.848-30.848 5.12-89.664-19.264-75.232-31.168-123.68-107.616-127.456-112.576-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624 26.176-62.304c6.176-6.304 16.384-9.184 26.176-9.184 3.168 0 6.016.16 8.576.288 7.52.32 11.296.768 16.256 12.64 6.176 14.88 21.216 51.616 23.008 55.392 1.824 3.776 3.648 8.896 1.088 13.856-2.4 5.12-4.512 7.392-8.288 11.744-3.776 4.352-7.36 7.68-11.136 12.352-3.456 4.064-7.36 8.416-3.008 15.936 4.352 7.36 19.392 31.904 41.536 51.616 28.576 25.44 51.744 33.568 60.032 37.024 6.176 2.56 13.536 1.952 18.048-2.848 5.728-6.176 12.8-16.416 20-26.496 5.12-7.232 11.584-8.128 18.368-5.568 6.912 2.4 43.488 20.48 51.008 24.224 7.52 3.776 12.48 5.568 14.304 8.736 1.792 3.168 1.792 18.048-4.384 35.52z"
          />
        </svg>
      )}

      {title === "chat" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M97.754 243a7.99 7.99 0 016.246-3h72V136c0-13.254 10.746-24 24-24h56V8a8 8 0 00-8-8H8a8 8 0 00-8 8v224a8 8 0 008 8h32a8 8 0 018 8v57.191zM176 104h-64V88h64zM48 40h160v16H48zm48 48v16H80V88zm-48 0h16v16H48zm0 48h112v16H48zm0 48h112v16H48zm0 0"
            data-original="#000000"
            fill={fill}
          />
          <path
            d="M200 368h144a7.99 7.99 0 016.246 3L400 433.191V376a8 8 0 018-8h32a8 8 0 008-8V136a8 8 0 00-8-8H200a8 8 0 00-8 8v224a8 8 0 008 8zm200-136h-16v-16h16zm-96-64h96v16h-96zm64 48v16h-16v-16zm-96-48h16v16h-16zm-32 0h16v16h-16zm0 48h96v16h-96zm0 48h160v16H240zm0 48h160v16H240zm0 0"
            data-original="#000000"
            fill={fill}
          />
        </svg>
      )}

      {title === "heart" && (
        <svg viewBox="0 -28 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={fill}
            d="M471.383 44.578C444.879 15.832 408.512 0 368.973 0c-29.555 0-56.621 9.344-80.45 27.77C276.5 37.07 265.605 48.45 256 61.73c-9.602-13.277-20.5-24.66-32.527-33.96C199.648 9.344 172.582 0 143.027 0c-39.539 0-75.91 15.832-102.414 44.578C14.426 72.988 0 111.801 0 153.871c0 43.3 16.137 82.938 50.781 124.742 30.992 37.395 75.535 75.356 127.117 119.313 17.614 15.012 37.579 32.027 58.309 50.152A30.023 30.023 0 00256 455.516c7.285 0 14.316-2.641 19.785-7.43 20.73-18.129 40.707-35.152 58.328-50.172 51.575-43.95 96.117-81.906 127.11-119.305C495.867 236.81 512 197.172 512 153.867c0-42.066-14.426-80.879-40.617-109.289zm0 0"
          />
        </svg>
      )}

      {title === "clock" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <g fill={fill}>
            <path d="M347.216 301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83-10.966 0-19.83 8.864-19.83 19.83v118.978c0 6.246 2.935 12.136 7.932 15.864l79.318 59.489a19.713 19.713 0 0011.878 3.966c6.048 0 11.997-2.717 15.884-7.952 6.585-8.746 4.8-21.179-3.965-27.743z" />
            <path d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.833 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.066-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.066 216.341 216.341S375.275 472.341 256 472.341z" />
          </g>
        </svg>
      )}

      {title === "question" && (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={fill}
            d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 405.332c-11.777 0-21.332-9.555-21.332-21.332s9.555-21.332 21.332-21.332 21.332 9.555 21.332 21.332-9.555 21.332-21.332 21.332zm33.77-135.637c-7.551 3.477-12.438 11.094-12.438 19.395v9.578c0 11.773-9.535 21.332-21.332 21.332s-21.332-9.559-21.332-21.332v-9.578c0-24.899 14.633-47.723 37.227-58.156 21.738-10.004 37.437-36.567 37.437-49.602C309.332 151.937 285.418 128 256 128s-53.332 23.937-53.332 53.332c0 11.777-9.54 21.336-21.336 21.336S160 193.109 160 181.332c0-52.926 43.07-96 96-96s96 43.074 96 96c0 28.824-25.004 71.191-62.23 88.363zm0 0"
          />
        </svg>
      )}

      {title === "document" && (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={fill}
            d="M106 512h300c24.814 0 45-20.186 45-45V150H346c-24.814 0-45-20.186-45-45V0H106C81.186 0 61 20.186 61 45v422c0 24.814 20.186 45 45 45zm60-301h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H166c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H166c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H166c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h120c8.291 0 15 6.709 15 15s-6.709 15-15 15H166c-8.291 0-15-6.709-15-15s6.709-15 15-15z"
          />
          <path
            fill={fill}
            d="M346 120h96.211L331 8.789V105c0 8.276 6.724 15 15 15z"
          />
        </svg>
      )}

      {title === "flag" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 403.04 403.04">
          <path
            fill={fill}
            d="M370.604 50.16a8 8 0 00-8.96 1.2c-21.68 19.44-41.6 28.88-60.96 28.88s-38.16-8.96-61.52-29.2c-50.72-43.28-100.56-43.2-158.8-1.84-4.08 2.88-4.08 2.88-3.36 86.16 0 32.72.56 73.28 0 78.96a8 8 0 0013.04 8 119.997 119.997 0 0170.72-24.96 106.875 106.875 0 0148 11.68 119.128 119.128 0 0052.72 14.08c96.64 0 112.56-158.16 113.2-164.88a7.999 7.999 0 00-4.08-8.08zM52.364 0h-7.52c-9.146 0-16.56 7.414-16.56 16.56v369.92c0 9.146 7.414 16.56 16.56 16.56h7.52c9.146 0 16.56-7.414 16.56-16.56V16.56C68.924 7.414 61.51 0 52.364 0z"
          />
        </svg>
      )}

      {title === "plus" && (
        <svg viewBox="0 0 426.667 426.667" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={fill}
            d="M405.332 192H234.668V21.332C234.668 9.559 225.109 0 213.332 0 201.559 0 192 9.559 192 21.332V192H21.332C9.559 192 0 201.559 0 213.332c0 11.777 9.559 21.336 21.332 21.336H192v170.664c0 11.777 9.559 21.336 21.332 21.336 11.777 0 21.336-9.559 21.336-21.336V234.668h170.664c11.777 0 21.336-9.559 21.336-21.336 0-11.773-9.559-21.332-21.336-21.332zm0 0"
          />
        </svg>
      )}

      {title === "close" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171">
          <path
            fill={fill}
            d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z"
          />
        </svg>
      )}

      {title === "menu" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333">
          <path
            fill={fill}
            d="M0 277.333h341.333V320H0zM0 149.333h341.333V192H0zM0 21.333h341.333V64H0z"
          />
        </svg>
      )}

      {title === "Unit Trusts" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
          <path
            fill={fill}
            d="M438.09 273.32h-39.596c4.036 11.05 6.241 22.975 6.241 35.404v149.65c0 5.182-.902 10.156-2.543 14.782h65.461c24.453 0 44.346-19.894 44.346-44.346v-81.581c.001-40.753-33.155-73.909-73.909-73.909zM107.265 308.725c0-12.43 2.205-24.354 6.241-35.404H73.91c-40.754 0-73.91 33.156-73.91 73.91v81.581c0 24.452 19.893 44.346 44.346 44.346h65.462a44.144 44.144 0 01-2.543-14.783v-149.65zM301.261 234.815h-90.522c-40.754 0-73.91 33.156-73.91 73.91v149.65c0 8.163 6.618 14.782 14.782 14.782h208.778c8.164 0 14.782-6.618 14.782-14.782v-149.65c0-40.754-33.156-73.91-73.91-73.91zM256 38.84c-49.012 0-88.886 39.874-88.886 88.887 0 33.245 18.349 62.28 45.447 77.524 12.853 7.23 27.671 11.362 43.439 11.362s30.586-4.132 43.439-11.362c27.099-15.244 45.447-44.28 45.447-77.524 0-49.012-39.874-88.887-88.886-88.887zM99.918 121.689c-36.655 0-66.475 29.82-66.475 66.475 0 36.655 29.82 66.475 66.475 66.475a66.095 66.095 0 0026.195-5.388c13.906-5.987 25.372-16.585 32.467-29.86a66.05 66.05 0 007.813-31.227c0-36.654-29.82-66.475-66.475-66.475zM412.082 121.689c-36.655 0-66.475 29.82-66.475 66.475a66.045 66.045 0 007.813 31.227c7.095 13.276 18.561 23.874 32.467 29.86a66.095 66.095 0 0026.195 5.388c36.655 0 66.475-29.82 66.475-66.475 0-36.655-29.82-66.475-66.475-66.475z"
          />
        </svg>
      )}

      {title === "Contributions and Withdrawals" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 401.60 401.60">
          <g fill={fill}>
            <path d="M116.682 229.329c11.286 0 22.195-.729 32.518-2.086V114.094c-10.322-1.356-21.232-2.085-32.518-2.085C52.241 112.009.001 135.702.001 164.93v11.477c0 29.227 52.24 52.922 116.681 52.922zM116.682 288.411c11.286 0 22.195-.729 32.518-2.084v-33.166c-10.325 1.356-21.229 2.095-32.518 2.095-56.25 0-103.199-18.054-114.227-42.082-1.606 3.5-2.454 7.124-2.454 10.839v11.477c0 29.228 52.24 52.921 116.681 52.921zM149.199 314.823v-2.578c-10.325 1.356-21.229 2.095-32.518 2.095-56.25 0-103.199-18.054-114.227-42.082C.848 275.757 0 279.381 0 283.096v11.477c0 29.229 52.24 52.922 116.681 52.922 12.887 0 25.282-.95 36.873-2.7-2.873-5.877-4.355-12.075-4.355-18.496v-11.476zM284.92 22.379c-64.441 0-116.681 23.693-116.681 52.921v11.477c0 29.228 52.24 52.921 116.681 52.921 64.44 0 116.681-23.693 116.681-52.921V75.3c0-29.228-52.241-52.921-116.681-52.921z" />
            <path d="M284.92 165.626c-56.25 0-103.199-18.053-114.227-42.082-1.606 3.499-2.454 7.123-2.454 10.839v11.477c0 29.228 52.24 52.921 116.681 52.921 64.44 0 116.681-23.693 116.681-52.921v-11.477c0-3.716-.848-7.34-2.454-10.839-11.028 24.029-57.977 42.082-114.227 42.082z" />
            <path d="M284.92 224.71c-56.25 0-103.199-18.054-114.227-42.082-1.606 3.499-2.454 7.123-2.454 10.839v11.477c0 29.229 52.24 52.922 116.681 52.922 64.44 0 116.681-23.693 116.681-52.922v-11.477c0-3.716-.848-7.34-2.454-10.839-11.028 24.029-57.977 42.082-114.227 42.082z" />
            <path d="M284.92 286.983c-56.25 0-103.199-18.054-114.227-42.082-1.606 3.5-2.454 7.123-2.454 10.838v11.478c0 29.228 52.24 52.921 116.681 52.921 64.44 0 116.681-23.693 116.681-52.921v-11.478c0-3.715-.848-7.34-2.454-10.838-11.028 24.027-57.977 42.082-114.227 42.082z" />
            <path d="M284.92 346.066c-56.25 0-103.199-18.053-114.227-42.081-1.606 3.5-2.454 7.125-2.454 10.838V326.3c0 29.228 52.24 52.921 116.681 52.921 64.44 0 116.681-23.693 116.681-52.921v-11.478c0-3.715-.848-7.34-2.454-10.838-11.028 24.028-57.977 42.082-114.227 42.082z" />
          </g>
        </svg>
      )}

      {title === "Fees" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.003 512.003">
          <g fill={fill}>
            <path d="M477.958 262.633a15.004 15.004 0 010-13.263l19.096-39.065c10.632-21.751 2.208-47.676-19.178-59.023l-38.41-20.38a15.005 15.005 0 01-7.796-10.729l-7.512-42.829c-4.183-23.846-26.241-39.87-50.208-36.479l-43.053 6.09a15.004 15.004 0 01-12.613-4.099l-31.251-30.232c-17.401-16.834-44.661-16.835-62.061 0L193.72 42.859a15.01 15.01 0 01-12.613 4.099l-43.053-6.09c-23.975-3.393-46.025 12.633-50.208 36.479l-7.512 42.827a15.008 15.008 0 01-7.795 10.73l-38.41 20.38c-21.386 11.346-29.81 37.273-19.178 59.024l19.095 39.064a15.004 15.004 0 010 13.263L14.95 301.699c-10.632 21.751-2.208 47.676 19.178 59.023l38.41 20.38a15.005 15.005 0 017.796 10.729l7.512 42.829c3.808 21.708 22.422 36.932 43.815 36.93 2.107 0 4.245-.148 6.394-.452l43.053-6.09a15 15 0 0112.613 4.099l31.251 30.232c8.702 8.418 19.864 12.626 31.03 12.625 11.163-.001 22.332-4.209 31.03-12.625l31.252-30.232c3.372-3.261 7.968-4.751 12.613-4.099l43.053 6.09c23.978 3.392 46.025-12.633 50.208-36.479l7.513-42.827a15.008 15.008 0 017.795-10.73l38.41-20.38c21.386-11.346 29.81-37.273 19.178-59.024l-19.096-39.065zM196.941 123.116c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139-54.139-24.287-54.139-54.139 24.287-54.139 54.139-54.139zm-27.944 240.77c-2.883 2.883-6.662 4.325-10.44 4.325s-7.558-1.441-10.44-4.325c-5.766-5.766-5.766-15.115 0-20.881l194.889-194.889c5.765-5.766 15.115-5.766 20.881 0s5.766 15.115 0 20.881l-194.89 194.889zm146.064 25.002c-29.852 0-54.139-24.287-54.139-54.139s24.287-54.139 54.139-54.139c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139z" />
            <path d="M315.061 310.141c-13.569 0-24.609 11.039-24.609 24.608s11.039 24.608 24.609 24.608c13.569 0 24.608-11.039 24.608-24.608s-11.039-24.608-24.608-24.608zM196.941 152.646c-13.569 0-24.608 11.039-24.608 24.608s11.039 24.609 24.608 24.609 24.609-11.039 24.609-24.609c-.001-13.568-11.04-24.608-24.609-24.608z" />
          </g>
        </svg>
      )}

      {title === "Security" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill={fill}
            d="M437.333 192h-32v-42.667C405.333 66.99 338.344 0 256 0S106.667 66.99 106.667 149.333V192h-32A10.66 10.66 0 0064 202.667v266.667C64 492.865 83.135 512 106.667 512h298.667C428.865 512 448 492.865 448 469.333V202.667A10.66 10.66 0 00437.333 192zM287.938 414.823a10.67 10.67 0 01-10.604 11.844h-42.667a10.67 10.67 0 01-10.604-11.844l6.729-60.51c-10.927-7.948-17.458-20.521-17.458-34.313 0-23.531 19.135-42.667 42.667-42.667s42.667 19.135 42.667 42.667c0 13.792-6.531 26.365-17.458 34.313l6.728 60.51zM341.333 192H170.667v-42.667C170.667 102.281 208.948 64 256 64s85.333 38.281 85.333 85.333V192z"
          />
        </svg>
      )}

      {title === "My Account" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill={fill}
            d="M256 0c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135S330.439 0 256 0zM423.966 358.195C387.006 320.667 338.009 300 286 300h-60c-52.008 0-101.006 20.667-137.966 58.195C51.255 395.539 31 444.833 31 497c0 8.284 6.716 15 15 15h420c8.284 0 15-6.716 15-15 0-52.167-20.255-101.461-57.034-138.805z"
          />
        </svg>
      )}

      {title === "Investment" && (
        <svg
          height="512"
          viewBox="0 0 510.035 510.035"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={fill}
            d="M325.344 0c-18.365 1.66-36.098 4.359-53.111 8.148-96.865 21.57-217.531 93.654-217.531 215.913 0 44.015 15.747 85.585 44.556 118.29 5.524-97.724 45.295-189.627 113.141-260.482C246.089 46.684 284.994 18.793 325.344 0zM128.574 495.035c0 8.284 6.716 15 15 15s15-6.716 15-15v-75.751a207.716 207.716 0 01-30-14.403z"
          />
          <path
            fill={fill}
            d="M454.74 13.446a15 15 0 00-14.407-10.825c-69.203.001-146.312 37.383-206.266 99.997-66.805 69.768-104.259 161.56-105.463 258.464l.002.096-.032.07v7.838a177.201 177.201 0 006.774 4.706c69.577 45.856 164.208 38.064 225.272-23.002 52.832-52.832 41.873-125.623 38.263-166.473-5.512-62.365-10.272-116.225 49.469-154.021a15 15 0 006.388-16.85z"
          />
        </svg>
      )}

      {title === "Popular Questions" && (
        <svg
          height="511pt"
          viewBox="0 -10 511.991 511"
          width="511pt"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={fill}
            d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"
          />
        </svg>
      )}
    </>
  )
}

export default Icon
